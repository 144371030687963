"use client";

import dynamic from "next/dynamic";

const AuthTokenProvider = dynamic(() => import("structure/AuthTokenProvider"), {
  ssr: false,
});
const TermsDialog = dynamic(() => import("shared/TermsDialog/TermsDialog"), {
  ssr: false,
});
const Modal = dynamic(() => import("shared/Modal/Modal"), {
  ssr: false,
});

const Snackbar = dynamic(() => import("shared/CustomSnackbar/CustomSnackbar"), {
  ssr: false,
});

const Footer = dynamic(() => import("shared/Footer/Footer"), {
  ssr: false,
});
const LoginModal = dynamic(() => import("shared/Modal/LoginModal"), {
  ssr: false,
});

export default function Template({ children }: { children: React.ReactNode }) {
  return (
    <div>
      <Snackbar />
      <Modal />
      <TermsDialog />
      <LoginModal />
      <AuthTokenProvider />
      {children}
      <Footer />
    </div>
  );
}
